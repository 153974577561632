/* eslint-disable */

import React from 'react'
import { Header }  from './components/Header'
import { Main }  from './components/MainComponents/MainCSS'
import { Footer }  from './components/Footer'


export const App = () => {
  return (
<>
    <Header />
    <Main />
    <Footer />
</>
  )
}
